<template>
    <div class="main-content">
        <b-card>
            <good-data-table 
                ref="accounts" 
                :button-label="$t('Add Account')"
                @add-new-info="New_Account"
                :columns="columns" 
                :no-serial="true"
                :total-column="'response.responseData.total'" 
                :data-column="'response.responseData.data'"
                :api-endpoint="'/account/accounts'" 
            />
        </b-card>
        <validation-observer ref="Create_Account">
            <b-modal no-close-on-backdrop no-close-on-esc hide-footer size="md" id="New_Account"
                :title="editmode ? $t('Edit') : $t('Add')">
                <b-form @submit.prevent="Submit_Account">
                    <b-row>
                        <!-- account_num -->
                        <b-col md="12">
                            <validation-provider name="account_num" :rules="{ required: true }"
                                v-slot="validationContext">
                                <b-form-group :label="$t('Account Number') + ' ' + '*'">
                                    <b-form-input :placeholder="$t('Enter Account Number')"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="account_num-feedback" label="Account Number"
                                        v-model="account.account_num"></b-form-input>
                                    <b-form-invalid-feedback id="account_num-feedback">{{ validationContext.errors[0]
                                        }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Name account -->
                        <b-col md="12">
                            <validation-provider name="Name account" :rules="{ required: true }"
                                v-slot="validationContext">
                                <b-form-group :label="$t('Account Name') + ' ' + '*'">
                                    <b-form-input :placeholder="$t('Enter account name')"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="account_name-feedback" label="account name"
                                        v-model="account.account_name"></b-form-input>
                                    <b-form-invalid-feedback id="account_name-feedback">{{ validationContext.errors[0]
                                        }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- initial_balance -->
                        <b-col md="12" v-if="!editmode">
                            <validation-provider name="initial_balance"
                                :rules="{ required: true, regex: /^\d*\.?\d*$/ }" v-slot="validationContext">
                                <b-form-group :label="$t('Initial Balance') + ' ' + '*'">
                                    <b-form-input type="number" :placeholder="$t('Enter Initial Balance')"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="initial_balance-feedback" label="Initial Balance"
                                        v-model="account.initial_balance"></b-form-input>
                                    <b-form-invalid-feedback id="initial_balance-feedback">{{
                                        validationContext.errors[0]
                                        }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Details -->
                        <b-col lg="12" md="12" sm="12">
                            <validation-provider name="Details">
                                <b-form-group slot-scope="{ valid, errors }" :label="$t('Details')">
                                    <textarea :class="{ 'is-invalid': !!errors.length }"
                                        :state="errors[0] ? false : (valid ? true : null)" v-model="account.note"
                                        rows="4" class="form-control" :placeholder="$t('Add more details')"></textarea>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12" class="mt-3">
                            <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i
                                    class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                            <div v-once class="typo__p" v-if="SubmitProcessing">
                                <div class="spinner sm spinner-primary mt-3"></div>
                            </div>
                        </b-col>

                    </b-row>
                </b-form>
            </b-modal>
        </validation-observer>
    </div>
</template>


<script>
import Datepicker from 'vuejs-datepicker';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`;
import { showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {
    components: {
        Datepicker, GoodDataTable
    },
    data() {
        return {
            isLoading: true,
            SubmitProcessing: false,
            serverParams: {
                columnFilters: {},
                sort: {
                    field: "id",
                    type: "desc"
                },
                page: 1,
                perPage: 10
            },
            selectedIds: [],
            totalRows: "",
            search: "",
            limit: "10",
            accounts: [],
            editmode: false,

            account: {
                id: "",
                account_num: "",
                account_name: "",
                initial_balance: 0,
                note: ""
            }
        };
    },
    computed: {
        columns() {
            return [
                {
                    label: this.$t("Account Number"),
                    field: "account_num",
                },
                {
                    label: this.$t("Account Name"),
                    field: "account_name",
                },
                {
                    label: this.$t("Balance"),
                    field: "balance",
                },
                {
                    label: this.$t("Notes"),
                    field: "note",
                },
                {
                    label: 'Action',
                    field: 'action',
                    sortable: false,
                    type: 'dropdown',
                    actions: [
                        {
                            isIcon: true,
                            iconHtml: this.$helpers.getIcons('edit'),
                            text: 'Edit',
                            action: props => {
                                this.Edit_Account(props)
                            },
                        },
                        {
                            isIcon: true,
                            iconHtml: this.$helpers.getIcons('delete'),
                            text: 'Delete',
                            action: props => {
                                this.$swal({
                                    title: this.$t('You want to delete?'),
                                    iconHtml: this.$helpers.swalIcon('alert.png'),
                                    showCancelButton: true,
                                    confirmButtonText: this.$t('Yes'),
                                    cancelButtonText: this.$t('No'),
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                        cancelButton: 'btn btn-danger ml-1',
                                        icon: 'border-0',
                                    },
                                    buttonsStyling: false,
                                }).then(result => {
                                    if (result.value) {
                                        this.Remove_Account(props)
                                    }
                                })
                            },
                        },
                    ],
                },
            ];
        }
    },

    methods: {
        //---- update Params Table
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        //---- Event Page Change
        onPageChange({ currentPage }) {
            if (this.serverParams.page !== currentPage) {
                this.updateParams({ page: currentPage });
                this.Get_Accounts(currentPage);
            }
        },

        //---- Event Per Page Change
        onPerPageChange({ currentPerPage }) {
            if (this.limit !== currentPerPage) {
                this.limit = currentPerPage;
                this.updateParams({ page: 1, perPage: currentPerPage });
                this.Get_Accounts(1);
            }
        },

        //---- Event Select Rows
        selectionChanged({ selectedRows }) {
            this.selectedIds = [];
            selectedRows.forEach((row, index) => {
                this.selectedIds.push(row.id);
            });
        },

        //---- Event on Sort Change
        onSortChange(params) {
            this.updateParams({
                sort: {
                    type: params[0].type,
                    field: params[0].field
                }
            });
            this.Get_Accounts(this.serverParams.page);
        },

        //---- Event on Search

        onSearch(value) {
            this.search = value.searchTerm;
            this.Get_Accounts(this.serverParams.page);
        },

        //---- Validation State Form

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },

        //------------- Submit Validation Create & Edit account
        Submit_Account() {
            this.$refs.Create_Account.validate().then(success => {
                if (!success) {
                    showErrorNotification(this, "Please fill the form data correctly");
                } else {
                    if (!this.editmode) {
                        this.Create_Account();
                    } else {
                        this.Update_Category();
                    }
                }
            });
        },

        //------ Toast
        makeToast(variant, msg, title) {
            this.$root.$bvToast.toast(msg, {
                title: title,
                variant: variant,
                solid: true
            });
        },

        //------------------------------ Modal  (create account) -------------------------------\\
        New_Account() {
            this.reset_Form();
            this.editmode = false;
            this.$bvModal.show("New_Account");
        },

        //------------------------------ Modal (Update account) -------------------------------\\
        Edit_Account(account) {
            this.Get_Accounts(this.serverParams.page);
            this.reset_Form();
            this.account = account;
            this.editmode = true;
            this.$bvModal.show("New_Account");
        },

        //--------------------------Get ALL Categories & Sub account ---------------------------\\

        Get_Accounts(page) {
            // Start the progress bar.
            axios
                .get(
                    apiUrl + "/accounts?page=" +
                    page +
                    "&SortField=" +
                    this.serverParams.sort.field +
                    "&SortType=" +
                    this.serverParams.sort.type +
                    "&search=" +
                    this.search +
                    "&limit=" +
                    this.limit
                )
                .then(response => {
                    this.accounts = response.data.accounts;
                    this.totalRows = response.data.totalRows;

                    // Complete the animation of theprogress bar.
                    this.isLoading = false;
                })
                .catch(response => {
                    // Complete the animation of theprogress bar.
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
        },

        //----------------------------------Create new account ----------------\\
        Create_Account() {
            this.SubmitProcessing = true;
            axios
                .post(apiUrl + "/accounts", {
                    account_num: this.account.account_num,
                    account_name: this.account.account_name,
                    initial_balance: this.account.initial_balance,
                    note: this.account.note,
                })
                .then(response => {
                    this.SubmitProcessing = false;
                    Fire.$emit("Event_Account");
                    showSuccessNotification(this, "Created successfully")
                })
                .catch(error => {
                    this.SubmitProcessing = false;
                    showErrorNotification(this, 'Failed')
                });
        },

        //---------------------------------- Update account ----------------\\
        Update_Category() {
            this.SubmitProcessing = true;
            axios
                .put(apiUrl + "/accounts/" + this.account.id, {
                    account_num: this.account.account_num,
                    account_name: this.account.account_name,
                    note: this.account.note,
                })
                .then(response => {
                    this.SubmitProcessing = false;
                    Fire.$emit("Event_Account");
                    showSuccessNotification(this, this.$t("Updated successfully"));
                })
                .catch(error => {
                    this.SubmitProcessing = false;
                    showErrorNotification(this, this.$t("Failed"));
                });
        },

        //--------------------------- reset Form ----------------\\

        reset_Form() {
            this.account = {
                id: "",
                account_num: "",
                account_name: "",
                initial_balance: 0,
                note: "",
            };
        },

        //--------------------------- Remove account----------------\\
        Remove_Account(props) {
            axios
            .delete(apiUrl + "/accounts/" + props.id)
            .then(() => {
                showSuccessNotification(this, "Deleted successfully");
            })
            .catch(() => {
                showErrorNotification(this, "Failed to delete");
            });
        },


    }, //end Methods

    //----------------------------- Created function-------------------

    mounted: function () {
        this.$refs.accounts.loadItems();

        Fire.$on("Event_Account", () => {
            setTimeout(() => {
                this.$refs.accounts.loadItems();
                this.$bvModal.hide("New_Account");
            }, 500);
        });

        Fire.$on("Event_delete_Account", () => {
            setTimeout(() => {
                this.$refs.accounts.loadItems();
            }, 500);
        });
    }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
